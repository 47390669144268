@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  background-color: #0A0C10;
  scroll-behavior: smooth;
  max-width: 1200px;
  overflow-x: hidden;
  margin: auto;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fullhide {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}

.mf {
  display: none;
  visibility: hidden;
}


@media only screen and (max-width: 1200px) {
  body {
    width: 900px;
  }
}

@media only screen and (max-width: 1050px) {
  body {
    width: 800px;
  }
}

@media only screen and (max-width: 830px) {
  body {
    width: 700px;
  }
}

@media only screen and (max-width: 500px) {
  body {
    width: 400px;
  }  

  nav {
    visibility: hidden;
    display: none;
  }

  .mf {
    display: block;
    visibility: visible;
  }

  #about-me {
    margin-top: 100px;
  }

  .clear {
    margin-top: 100px;
  }
} 

@media only screen and (max-width: 440px) {
  body {
    width: 380px;
  }  
}

@media only screen and (max-width: 410px) {
  body {
    width: 340px;
  }  
} 