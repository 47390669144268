.Skill-image-holder {
    width: 70px;
    margin: 5px;
}

.Skill-image-holder img {
    width: 100%;
    padding: 15px;
}

@media only screen and (max-width: 500px) {
    .Skill-image-holder {
        width: 25px;
        margin: 2px;
    }
    
    .Skill-image-holder img {
        padding: 10px;
    }
}