.Icon-Text-Holder {
    position: relative;
    width: 230px;
    height: 200px;
    cursor: pointer;
    user-select: none;
}

.Icon-Text-Holder img {
    position: absolute;
    text-decoration: none;
}

.Thin {
    top: 30px;
    left: 10%;
    z-index: 7;
    transition: all 0.3s ease-in-out;
}

.Thin.hide {
    transform: scale(0);
    opacity: 0;
}

.Solid {
    top: -168px;
    left: -147px;
    z-index: 99;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.Solid.show.highlight {
    transform: scale(1);
}

.Text {
    z-index: 9;
    top: -250px;
    left: 70px;
    transform: translateY(-150%);
    transition: all 0.4s cubic-bezier(0.01, 0.98, 0.01, 0.98) 0s;
}

.Text.highlight {
    transform: translateY(0);
}

@media only screen and (max-width: 1200px) {
    .Icon-Text-Holder {
        width: 150px;
        height: 150px;
    }

    .Icon-Text-Holder .Thin {
        width: 100%;
    }

    .Icon-Text-Holder .Text {
        width: 60%;
    }

    .Icon-Text-Holder .Solid {
        width: 380px;
    }

    .Thin {
        z-index: 8;
        top: 30px;
        left: 10%;
        transition: all 0.3s ease-in-out;
    }

    .Solid {
        z-index: 10;
        top: -110px;
        left: -105px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .Text {
        z-index: 9;
        top: -210px;
        left: 45px;
        transform: translateY(-150%);
        /* transform: translateY(0); */
        transition: all 0.4s cubic-bezier(0.01, 0.98, 0.01, 0.98) 0s;
    }
}