.this-mobile {
    display: none;
    visibility: hidden;
}

.sbg-shadow {
    position: absolute;
    border-radius: 50%;
    z-index: 1;
}

.sball1 {
    width: 450px;
    height: 450px;
    background-color: #D04D4D;
    left: -150px;
    top: -150px;
    filter: blur(450px);
}

.sball2 {
    width: 400px;
    height: 400px;
    background-color: #3676B0;
    right: -90px;
    top: -90px;
    filter: blur(350px);
}

.sball3 {
    width: 400px;
    height: 400px;
    background-color: #FFB800;
    right: 800px;
    bottom: -400px;
    filter: blur(400px);
}

.Header--controller {
    position: relative;
}

.social-counter {
    position: relative;
    height: 450px;
    z-index: 999;
}

.social-counter div {
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 1200px) {
    .social-counter .Icon-Text-Holder:nth-child(2) .Text {
        width: 50%;
    }
}

@media only screen and (max-width: 1050px) {
    .this-mobile {
        position: relative;
        display: block;
        visibility: visible;
        z-index: 99999;
    }

    .social-counter {
        display: none;
        visibility: hidden;
    }

    .Footer {
        display: none;
        visibility: hidden;
    }
}