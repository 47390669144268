header {
    position: absolute;
    top: 6%;
    display: flex;
    z-index: 99999;
    transition: all 0.5 ease-in-out;
}

.header {
    position: relative;
    width: 95%;
    margin: auto;
}

.header ul {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
}

.header ul li {
    cursor: pointer;
    position: relative;
    display: flex;
    color: #fff;
    width: 150px;
    height: 45px;
    justify-content: center;
    align-items: center;
    transition: all 0.8s cubic-bezier(0.01, 0.08, 0.01, 0.08) 2s;
}

.header ul li p {
    font-size: 18px;
    font-weight: 300;
    z-index: 999;
}

.header ul li .h-img {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.01, 0.48, 0.01, 0.98) 0s;
}

.header ul li .home {
    width: 32px;
    height: 32px;
    left: 21%;
    top: 5px;
}

.header ul li:nth-child(1):hover .home {
    transform: translateX(50px) scale(120%);
    filter: drop-shadow(0px 0px 7px #FF0000);
}

.header ul li .user {
    width: 30px;
    height: 30px;
    left: 13%;
    top: 7px;
}

.header ul li:nth-child(2):hover .user {
    transform: translateX(80px) scale(120%);
    filter: drop-shadow(0px 0px 7px #00D1FF);
}

.header ul li .project {
    width: 31px;
    height: 31px;
    left: 26px;
    top: 6px;
}

.header ul li:nth-child(3):hover .project {
    transform: translateX(70px) scale(120%);
    filter: drop-shadow(0px 0px 7px #FAFF00);
}

.header ul li .star {
    width: 33px;
    height: 33px;
    left: 3px;
    top: 5px;
}

.header ul li:nth-child(4):hover .star {
    transform: translateX(110px) scale(120%);
    filter: drop-shadow(0px 0px 7px #E339FF);
}

.header ul li .store {
    width: 30px;
    height: 30px;
    left: 35px;
    top: 6px;
}

.header ul li:nth-child(5):hover .store {
    transform: translateX(45px) scale(120%);
    filter: drop-shadow(0px 0px 7px #00FF57);
}

.header ul li img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 500px) {
    nav {
        height: 450px;
    }
    
    .welcomer {
        position: absolute;
        bottom: 35%;
    }
    .welcomer > h1 {
        font-size: 22px;
    }
} 

@media only screen and (max-width: 440px) {
    .welcomer h1 {
        font-size: 20px;
    }
}