.sp-bg {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.sp-bg img {
    object-fit: cover;
    width: 100%;
}

.sp-polygon1 {
    width: 800px;
    height: 800px;
    top: 500px;
    left: 20px;
    animation: moveupdown 3s infinite alternate ease-in-out;
}

.sp-polygon2 {
    width: 800px;
    height: 800px;
    top: 2900px;
    left: -130px;
    transform: rotate(-10deg);
    animation: moveupdown 3s infinite alternate ease-in-out;
}

.sp-polygon3 {
    width: 800px;
    height: 800px;
    top: 1700px;
    right: -420px;
    transform: rotate(0);
    animation: protate 4s infinite ease-in-out;
}

.sp-polygon4 {
    width: 1100px;
    height: 1100px;
    top: 3800px;
    right: -700px;
    transform: rotate(-15deg);
    animation: moveupdown 3s infinite alternate ease-in-out;
}

@keyframes moveupdown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(110px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes protate {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}