.mobile--footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 55px;
    background: rgba( 255, 255, 255, 0.35 );
    box-shadow: 0 8px 32px 0 rgba(6, 7, 24, 0.37);
    backdrop-filter: blur( 6px );
    border-radius: 10px 10px 0 0;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    z-index: 99999;
}

.mobile--footer ul {
    margin-top: 6px;
    width: 85%;
    display: flex;
    justify-content: space-around;
}

.mobile--footer ul li {
    padding: 5px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    text-decoration: none;
    list-style: none;
}

.mobile--footer ul li img {
    width: 100%;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
}

@media only screen and (max-width: 440px) {
    .mobile--footer ul {
        margin: 6px;
        padding: 0;
        width: 100%;
        /* background-color: red; */
        display: flex;
        justify-content: space-evenly;
    }
}

