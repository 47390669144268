.sliders-holder {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 600vh;
    top: 0;
    left: 0;
    display: flex;
    z-index: 9999999999999999;
}

.slide-left {
    width: 50%;
    height: 100%;
    background-color: #0A0C10;
    transition: all 0.1s ease-in-out;
    animation: slideleft 0.6s ease-in-out forwards;
    animation-delay: 3.5s;
}

.slide-right {
    width: 50%;
    height: 100%;
    background-color: #0A0C10;
    transition: all 0.1s ease-in-out;
    animation: slideright 0.6s ease-in-out forwards;
    animation-delay: 3.5s;
}

.loader-holder {
    position: absolute;
    top: 10%;
    left: 47%;
    width: 100vh;
    height: 100%;
    z-index: 999999999999999999;
    animation: fadespline 0.6s ease-in-out forwards;
    animation-delay: 3.0s;
}

.loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 6rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.0s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
}

.loader:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem #fff;
    animation-name: pulsIn;
}

.loader:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 #fff;
}

@keyframes pulsIn {
    0% {
        box-shadow: inset 0 0 0 1rem #fff;
        opacity: 1;
    }
    50%, 100% {
        box-shadow: inset 0 0 0 0 #fff;
        opacity: 0;
    }
}

@keyframes pulsOut {
    0%, 50% {
        box-shadow: 0 0 0 0 #fff;
        opacity: 0;
    }
    100% {
        box-shadow: 0 0 0 1rem #fff;
        opacity: 1;
    }
}
    

@keyframes slideleft {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateX(-60%);
    }

    65% {
        transform: translateX(-30%);
    }

    75% {
        transform: translateX(-30%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes slideright {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateX(60%);
    }

    65% {
        transform: translateX(30%);
    }

    75% {
        transform: translateX(30%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes fadespline {
    0%{
        visibility: visible;
        opacity: 1;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .sliders-holder {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}