.Footer {
    margin: 400px 0 80px 0;
    position: relative;
    height: 250px;
    z-index: 99;
}

.footer-holder {
    width: 100%;
    height: 90%;
    color: #fff;
    border-radius: 35px;
    background-color: #090A0E;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.4), inset 5px 5px 15px -15px rgb(255, 255, 255) ;
}

.footer-info .heading {
    margin-top: 45px;
    font-size: 28px;
}

.footer-info p:nth-child(2) {
    justify-content: center;
    display: flex;
}

.Footer .Skill-image-holder {
    animation: none !important;
    width: 2%;
    padding: 0 25px 0 0;
    position: relative;
    top: -20px;
}