.working-h1 {
    margin: 25% auto;
    justify-content: center;
    font-size: 52px;
    font-weight: 800;
    color: #fff;
    z-index: 9999;
}

.backtohome {
    cursor: pointer;
    margin: 0 auto;
    width: 20%;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    border: 1px solid #fff;
    justify-content: center;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
}

.backtohome:hover {
    width: 25%;
}

.wball1 {
    position: absolute;
    top: -100px;
    left: -100px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: aqua;
    filter: blur(400px);
    z-index: 1;
}

.wball2 {
    position: absolute;
    bottom: 0px;
    right: -100px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: rgb(255, 238, 0);
    filter: blur(400px);
    z-index: 1;
}