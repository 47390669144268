.icon {
    width: 150px;
    height: 150px;
}

.info--holder {
    margin-top: -15px;
}

.info--holder h3 {
    width: 170px;
    color: #fff;
    font-size: 30px;
    letter-spacing: 2px;
}

.info--holder p {
    margin-top: -18%;
    color: #fff;
    font-size: 20px;
}

@media only screen and (max-width: 500px) {
    .icon {
        font-size: 30px !important;
    }

    .icon img {
        width: 25%;
    }

    .info--holder h3 {
        font-size: 16px !important;
    }

    .info--holder p {
        font-size: 10px !important;
        margin-top: -10px;
    }
}

@media only screen and (max-width: 440px) {
    .info--holder h3 {
        font-size: 14px !important;
        width: 100%;
    }
}