.product-information {
    margin-top: 7%;
    color: #fff;
    display: flex;
    position: relative;
    text-align: left;
    height: 645px;
}

.personalinfo {
    display: block;
}

.personalinfo input {
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    width: 60%;
    padding: 0px 0px 5px 10px;
    margin-top: 30px;
    font-weight: 100;
    font-style: italic;
    color: #fff;
}

.func-dropdown {
    position: absolute;
    display: flex;
    width: 55%;
    height: 45px;
    line-height: 45px;
    margin-top: 10px;
    background: rgba( 255, 255, 255, 0.2);
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7px );
    border: 1px solid rgba( 255, 255, 255, 0.5);
    border-radius: 8px;
    color: #fff;
    padding: 2px 20px;
    text-align: left;
    z-index: 9999;
    font-size: 14px;
    transition: all 0.4s ease-in-out;
}

.arrow {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-top: 5px;
    transform: rotate(90deg);
    transition: transform 0.4s ease-in-out;
}

.arrow.show {
    transform: rotate(0deg);
}

.arrow img {
    width: 100%;
}

.func-dropdown.show {
    position: absolute;
    height: 524px;
}

.func-dropdown a {
    user-select: none;
    width: 100%;
    height: 37px;
    line-height: 37px;
    color: #fff;
    text-decoration: none;
    position: relative;
    left: -20px;
    display: block;
    font-size: 14px;
    text-shadow: 1px 1px 1px rgba(0,0,0, 1),
    3px 3px 10px rgba(0,0,0, 0.4);
    padding: 0 51px 0 20px;
    transform: translateY(-50%);
}

.func-dropdown a p {
    margin: 0;
}

.func-dropdown a:nth-child(even) {
    transform: translateY(-100%);
}

.func-dropdown a.hidethis {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.1s ease-out, opacity 0.1s ease-out, transform 0.2s ease-out;
}

.func-dropdown a.show {
    transform: translateY(0);
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out,transform 0.3s ease-in-out;
    transition-delay: 0.3s;
}

.func-dropdown a:hover {
    cursor: pointer;
    background: rgba( 255, 255, 255, 0.40);
}

.func-dropdown a:nth-last-child(1) {
    border-radius: 0 0 8px 8px;
}

.set-info {
    width: 60%;
    position: relative;
}

.functionality {
    margin-top: 50px;
}

.functionality-details {
    position: relative;
    height: 100%;
    width: 40%;
}

.functionality-details .product-desc {
    font-size: 15px;
    font-weight: 300;
    line-height: 27px;
    width: 100%;
    height: 70%;
}

.functionality-details .send-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 140px;
    height: 45px;
    background-color: #00FF57;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0, 0.6);
    transition: all 0.3s ease-in-out;
}

.functionality-details .send-btn:hover {
    transform: translate(-2px, -2px);
    box-shadow: 6px 6px 10px 2px rgba(0,0,0,1);
}

.functionality-details .send-btn p {
    margin: 0;
    line-height: 45px;
    color: #fff;
    font-size: 21px;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.8);
}

.product-title {
    font-size: 24px;
    font-weight: 500;
}

.product-title span {
    color: #00FF57;
}

.product-title-last span {
    color: #00FFBD;
}

.user-desc {
    position: relative;
    width: 60%;
    height: 200px;
    margin-top: 19%;
}

.text-holder {
    display: flex;
    width: 100%;
    height: 100%;
}

.user-desc textarea {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    min-width: 50%;
    min-height: 50%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    outline: none;
    color: #fff;
    background: rgba( 255, 255, 255, 0.2);
    border: 1px solid rgba( 255, 255, 255, 0.5);
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7px );
}

.product-details {
    color: #fff;
    text-align: left;
    margin-top: 10%;
}

.product-details P {
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
}

.u-ours {
    color: #ffee00;
    font-size: 17px;
    font-weight: 500;
}

@media only screen and (max-width: 830px) {
    .user-desc {
        margin-top: 35%;
    }

    .func-dropdown  {
        width: 75%;
    }

    .func-dropdown a {
        width: 100%;
        font-size: 11px;
    }
}

@media only screen and (max-width: 500px) {

    .to-store {
        display: none;
        visibility: hidden;
    }
    
    .store-title {
        margin-top: 30%;
    }
    .store-title p {
        font-size: 25px;
    }

    .product-title {
        font-size: 19px;
    }

    .product-information {
        margin-top: 20%;
        display: block;
    }
    
    .personalinfo input {
        width: 100%;
        margin-top: 40px;
    }

    .func-dropdown {
        width: 130% !important;
        height: 40px;
        line-height: 40px;
        font-size: 11px;
    }

    .func-dropdown.show {
        height: 518px;
    }

    .arrow  {
        margin-top: 3px;
    }

    .user-desc {
        width: 140%;
        margin-top: 60%;
    }

    .func-dropdown a {
        width: 100%;
        font-size: 11px;
    }

    .product-details {
        margin-top: 20%;
    }

    .functionality-details {
        margin-top: 20%;
        width: 90%;
    }

    .product-details-margin {
        margin-top: 150%;
    }

    .functionality-details .send-btn {
        bottom: 45%;
    }
}