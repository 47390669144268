nav {
    width: 100%;
    height: 450px;
    position: relative;
}

header {
    position: absolute;
    width: 100%;
    top: 10%;
    display: flex;
}

.welcomer {
    position: absolute;
    bottom: 20%;
}


.welcomer > h1 {
    color: #fff;
    font-size: 42px;
}

.welcomer > h1 > span {
    color: #D04D4D;
}

.bubble-holder {
    display: flex;
}

.left--side {
    position: absolute;
    left: 45%;
    z-index: 1;
    transition: all 0.5s cubic-bezier(0.07, 0.6799999999999999, 0.48, 0.94) 0s;
    /* transition: all 0.4s cubic-bezier(0.88, 0.020000000000000018, 0.09, 0.9299999999999999) 0s; */
    /* transition: all 0.6s cubic-bezier(0.05, 0.43999999999999995, 0.98, 0) 0s; */
    opacity: 0;
    visibility: hidden;
}

.left--side.highlight {
    left: 30%; 
    opacity: 1;
    visibility: visible;
    animation: movemore 0.7s;
}

@keyframes movemore {
    0% {
        left:45%;
    }

    50% {
        left: 25%;
    }

    100% {
        left:30%;
    }
}

.island {
    width: 115px;
    height: 35px;
    background: rgba( 255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 35px;
    border: 1px solid rgba( 255, 255, 255, 0.4);
    margin: auto;
    color: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 32px;
    z-index: 3;
    cursor: pointer;
}

.right--side {
    position: absolute;
    right: 45%;
    z-index: 1;
    /* transition: all 0.4s ease-in-out; */
    /* transition: all 0.4s cubic-bezier(0.88, 0.020000000000000018, 0.09, 0.9299999999999999) 0s; */
    transition: all 0.5s cubic-bezier(0.07, 0.6799999999999999, 0.48, 0.94) 0s;
    opacity: 0;
    visibility: hidden;
}

.right--side.highlight {
    right: 30%;
    opacity: 1;
    visibility: visible;
    animation: movemore2 0.7s;
}

@keyframes movemore2 {
    0% {
        right:45%;
    }

    50% {
        right: 25%;
    }

    100% {
        right:30%;
    }
}

.bubbles {
    width: 35px;
    height: 35px;
    background: rgba( 255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 35px;
    border: 1px solid rgba( 255, 255, 255, 0.4);
    margin: 0 10px;
    color: #fff;
    cursor: pointer;
}

.bubbles img {
    margin: 6px auto;
    align-items: center;
    width: 65%;
}

@media only screen and (max-width: 1200px) {
    .right--side.highlight {
        right: 23%;
    }

    .left--side.highlight {
        left: 23%; 
    }

    @keyframes movemore {
        0% {
            left:45%;
        }
    
        50% {
            left: 19%;
        }
    
        100% {
            left:23%;
        }
    }

    @keyframes movemore2 {
        0% {
            right:45%;
        }
    
        50% {
            right: 19%;
        }
    
        100% {
            right:23%;
        }
    }
}


@media only screen and (max-width: 500px) {
    nav {
        height: 450px;
    }
    
    .welcomer {
        position: absolute;
        bottom: 35%;
    }
    .welcomer > h1 {
        font-size: 22px;
    }

    .left--side {
        left: 35%;
    }

    .right--side {
        right: 35%;
    }

    .left--side.highlight {
        left: 3%; 
    }

    .right--side.highlight {
        right: 3%;
    }

    @keyframes movemore {
        0% {
            left:35%;
        }
    
        50% {
            left: 1%;
        }
    
        100% {
            left:3%;
        }
    }

    @keyframes movemore2 {
        0% {
            right:35%;
        }
    
        50% {
            right: 1%;
        }
    
        100% {
            right:3%;
        }
    }
} 

@media only screen and (max-width: 440px) {
    .welcomer h1 {
        font-size: 20px;
    }
}