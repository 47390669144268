.store-title {
    color: #fff;
    text-align: left;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
}

.store-title span {
    color: #D04D4D;
}

.product-cards {
    margin-top: 1px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .product-cards {
        justify-content: center;
    }
    
    .pc:nth-last-child(1) {
        margin-bottom: 150px;
    }
}