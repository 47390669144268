.SkillBox-Holder .box--right img {
    position: relative; 
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.SkillBox-Holder {
    width: 100%;
    height: 600px;
    position: relative;
    margin-bottom: 5%;
    z-index: 99;
}

.Skill--holder {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 30px;
    border: 1px solid #fff;
    background-color: #0A0C10;
}

.Skill--holder .box {
    padding: 35px 40px;
}

.Skill--holder .box--left {
    width: 35%;
    text-align: left;
    color: #fff;
}

.Skill--holder .box--left h2 {
    font-size: 32px;
    margin: 0;
}

.Skill--holder .box--left p {
    line-height: 22px;
}

.Skill--holder .box--right {
    padding-left: 0;
    width: 65%;
    display: flex;
}

.box--1-2 {
    width: 55%;
    display: block;
}

.box--1-2 .box--1 {
    height: 70%;
    display: flex;
    justify-content: space-between;
}

.box--1-2 .box--1 div {
    width: 100%;
}

.box--1-2 .box--2 {
    height: 30%;
    position: relative;
}

.box--1-2 .box--2 div {
    width: 100%;
    height: 93%;
    position: absolute;
    bottom: 0;
}

.box--3 {
    width: 45%;
}

.box--3 div {
    position: relative;
    width: 96%;
    height: 100%;
    float: right;
    border-radius: 20px;
}

@media only screen and (max-width: 1200px) {
    .Skill--holder .box--left h2 {
        font-size: 28px;
        margin: 0;
    }
    
    .Skill--holder .box--left p {
        line-height: 22px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 1050px) {
    .Skill--holder .box--left h2 {
        font-size: 20px;
        margin: 0;
    }
    
    .Skill--holder .box--left p {
        line-height: 22px;
        font-size: 12px;
    }
}


@media only screen and (max-width: 500px) {

    .SkillBox-Holder {
        height: 100%;
        margin-bottom: 6%;
    }

    .Skill--holder {
        display: block;
    }

    .Skill--holder .box {
        margin: auto;
        padding: 20px 15px;
    }

    .Skill--holder .box--left {
        width: 90%;
    }
    
    .Skill--holder .box--right {
        width: 90%;
    }

    .Skill--holder .box--left h2 {
        margin-top: 10px;
    }

    .Skill--holder .box--left p {
        font-size: 10px;
        line-height: 18px;
    }
  } 