.bg-shadow {
    position: absolute;
    border-radius: 50%;
    z-index: 1;
}

.movethisshit { 
    position: absolute;
    right: -50%;
    top: 60%;
    z-index: -99;
}

.spline-cube {
    width: 100%;
    height: 100%;
}

.ball1 {
    width: 1500px;
    height: 1500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    right: -550px;
    top: -550px;
    filter: blur(100px);
}

.ball1 img {
    width: 100%;
}

.ball2 {
    width: 500px;
    height: 500px;
    background-color: #517393;
    left: -260px;
    top: 650px;
    filter: blur(300px);
}

.ball3 {
    width: 500px;
    height: 500px;
    background-color: rgb(174, 59, 59);
    right: -300px;
    top: 1550px;
    filter: blur(500px);
}

.ball4 {
    width: 550px;
    height: 550px;
    background-color: #FFB800;
    left: -550px;
    top: 2250px;
    filter: blur(500px);
}

.ball5 {
    width: 550px;
    height: 550px;
    background-color: #A373DF;
    right: -150px;
    top: 4150px;
    filter: blur(500px);
}

.Header--controller nav {
    height: 150px;
}

.Header--controller header {
    top: 30%;
}

.title-holder h2 {
    text-align: left;
    font-size: 32px;
    color: #fff;
}

section h2 {
    text-align: center;
    font-size: 34px;
    color: #fff;
    margin-bottom: 70px;
}

.title-holder h2 > span {
    opacity: 0.2;
    letter-spacing: 125px;
    margin: 0 -70px 0 15px;
}

section {
    position: relative;
    margin-top: 200px;
    z-index: 9;
}


section img {
    user-select: none;
    -webkit-user-select: none;
}

.secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    height: 400px;
}

.text-holder {
    width: 65%;
    height: 100%;
}

.text-holder > p {
    width: 90%;
    color: #fff;
    text-align: left;
    line-height: 26px;
    font-size: 15px;
    font-weight: 300;
}

.images-holder {
    position: relative;
    width: 35%;
    height: 100%;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.image-cover {
    width: 200px;
    height: 200px;
    clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);
}

.one {
    position: absolute;
    top: 2%;
    left: 18%;
    transform: rotate(7deg);
}

.one img {
    user-select: none;
    position: relative;
    left: -5px;
}

.two {
    position: absolute;
    top: 16%;
    left: 40%;
    transform: rotate(30deg);
}

.two img {
    position: relative;
    left: -10px;
}

.three {
    position: absolute;
    top: 30%;
    left: 15%;
    transform: rotate(4deg);
}

.three img {
    position: relative;
    left: -10px;
}

.static--holder {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* .static--holder .icon:nth-child(1) {
    filter: drop-shadow(2px 2px 10px #fff);
} */

.skills--holder {
    display: flex;
}

.skill-left {
    width: 50%;
}

.skill--title {
    width: 100%;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
}

.skill--title div {
    width: 60px;
    padding: 10px;
}

.skill--title img {
    width: 100%;
    animation: HtmlImage 5s infinite alternate ease-in-out;
}

@keyframes HtmlImage {
    0% {
        transform: translateY(10px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(10px);
    }
}

.skill-left .skill--short {
    width: 80%;
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    text-align: left;
    margin-top: 30px;
}

.skill--desc div {
    align-items: center;
    display: flex;
}

.skill--position {
    font-weight: 300;
    color: #fff;
    font-size: 20px;
    margin-bottom: -30px;
}

.skill--position--subset {
    width: 300px;
}

.skill-left ul {
    width: 100%;    
}

.skill-left ul li {
    display: flex;
    height: 35px;
}

.skill-left ul li p {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
}

.skill--circle {
    width: 18px;
    height: 18px;
    background-color: #2EC762;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.5),
    0px 0px 15px 1px rgba(0, 255, 87, 0.5),
    0px 0px 35px 1px rgba(0, 255, 87, 0.5);
    animation: blink 2.5s infinite;
}

@keyframes blink {
    0% {
        background-color: #2EC762;
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.5),
        0px 0px 15px 1px rgba(0, 255, 87, 0.5),
        0px 0px 35px 1px rgba(0, 255, 87, 0.5);
    }
    50% {
        background-color: #2EC762;
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.5),
        0px 0px 15px 1px rgba(0, 255, 87, 0.5),
        0px 0px 35px 1px rgba(0, 255, 87, 0.5);
    }
    55% {
        background-color: rgba(46, 199, 98, 0.0);
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0),
        0px 0px 15px 1px rgba(0, 255, 87, 0),
        0px 0px 35px 1px rgba(0, 255, 87, 0);
    }
    60% {
        background-color: #2EC762;
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.5),
        0px 0px 15px 1px rgba(0, 255, 87, 0.5),
        0px 0px 35px 1px rgba(0, 255, 87, 0.5);
    }
    65% {
        background-color: rgba(46, 199, 98, 0.0);
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0),
        0px 0px 15px 1px rgba(0, 255, 87, 0),
        0px 0px 35px 1px rgba(0, 255, 87, 0);
    }
    70% {
        background-color: rgba(46, 199, 98, 0.6);
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.3),
        0px 0px 15px 1px rgba(0, 255, 87, 0.3),
        0px 0px 35px 1px rgba(0, 255, 87, 0.3)
    }
    95% {
        background-color: rgba(46, 199, 98, 0.6);
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.3),
        0px 0px 15px 1px rgba(0, 255, 87, 0.3),
        0px 0px 35px 1px rgba(0, 255, 87, 0.3)
    }
    100% {
        background-color: #2EC762;
        box-shadow: 0px 0px 5px 1px rgba(0, 255, 87, 0.5),
        0px 0px 15px 1px rgba(0, 255, 87, 0.5),
        0px 0px 35px 1px rgba(0, 255, 87, 0.5);
    }
}

.skill--line {
    background: linear-gradient(to bottom, #00FF57 0%, #D04D4D 100%);
    width: 4px;
    height: 140px;
    border-radius: 10px;
    margin: 15px -30px 0 30px;
}

.skill--position p {
    margin-left: 10px;
}


/* Skill Right */
.skill-right {
    width: 50%;
}

.skills--box {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Skill-image-holder {
    animation: SkillImage1 7s infinite alternate ease-in-out;
}

@keyframes SkillImage1 {
    0% {
        transform: rotate(15deg) translateY(15px);
    }

    50% {
        transform: rotate(-15deg) translateY(-15px);
    }

    100% {
        transform: rotate(15deg) translateY(15px);
    }
}

.Skill-image-holder:nth-child(even) {
    animation: SkillImage2 7s infinite alternate ease-in-out;
}

@keyframes SkillImage2 {
    0% {
        transform: rotate(-15deg) translateY(-15px);
    }

    50% {
        transform: rotate(15deg) translateY(15px);
    }

    100% {
        transform: rotate(-15deg) translateY(-15px);
    }
}

.Piece--word span:nth-child(1) {
    color: #FFB800;
}

.Piece--word span:nth-child(2) {
    color: #CE5353;
}

.Piece--word span:nth-child(4) {
    color: #00FF57;
}

/* Project */
.Projects {
    position: relative;
}

.projects-holder {
    display: flex;
    justify-content: center;
    background-color: rgb(15,17,17);
    position: relative;
    height: 300px;
    padding: 0 40px;
    border: 1px solid #fff;
    transition: all 1s ease-in-out;
    border-radius: 45px;
}

.projects-holder:hover {
    box-shadow: 0 5px 35px 0px rgba(0,0,0,0.1);
    border: 1px solid rgb(15,17,17);;
}

.projects-holder:hover::before, .projects-holder:hover::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 300px;
    background-color: #00f2ff;
    box-shadow: 1px 1px 10px #00f2ff;
    border-radius: 45px;
    z-index: -1;
    animation: 1s clockwise infinite;
}

.projects-holder:hover::after {
    background-color: #ae00ff;
    box-shadow: 1px 1px 10px #ae00ff;
    animation: 1s anticlockwise infinite;
}

@keyframes clockwise {
    0%{
        top: -5px;
        right: 0px;
    }
    12%{
        top: -2px;
        right: 2px;
    }
    25%{
        top: 0px;
        right: 5px;
    }
    37%{
        top: 2px;
        right: 2px;
    }
    50%{
        top: 5px;
        right: 0px;
    }
    62%{
        top: 2px;
        right: -2px;
    }
    75%{
        top: 0px;
        right: -5px;
    }
    87%{
        top: -2px;
        right: -2px;
    }
    100% {
        top: -5px;
        right: 0;
    }
}

@keyframes anticlockwise {
    0%{
        top: -5px;
        left: 0px;
    }
    12%{
        top: -2px;
        left: 2px;
    }
    25%{
        top: 0px;
        left: 5px;
    }
    37%{
        top: 2px;
        left: 2px;
    }
    50%{
        top: 5px;
        left: 0px;
    }
    62%{
        top: 2px;
        left: -2px;
    }
    75%{
        top: 0px;
        left: -5px;
    }
    87%{
        top: -2px;
        left: -2px;
    }
    100% {
        top: -5px;
        left: 0;
    }

}

.projects-holder .p--left {
    width: 60%;
    text-align: left;
    color: #fff;
}

.projects-holder .p--left .p--title {
    font-weight: 600;
    font-size: 28px;
    margin-top: 35px;
}

.projects-holder .p--left p {
    font-size: 16px;
    width: 400px;
    margin: 25px 0;
}

.projects-holder .p--right {
    user-select: none;
    width: 40%;
    color: #fff;
    position: relative;
}

.p--right .p--btn {
    position: absolute;
    width: 350px;
    height: 50px;
    right: 0;
    bottom: 35px;
    line-height: 50px;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    background-color: rgb(24, 27, 27);
    border-radius: 15px;
    border: none;
    outline: none;
    z-index: 0;
    cursor: pointer;
}

.p--right .p--btn:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 10s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 15px;
}

.p--right .p--btn:active {
    color: #fff;
    text-shadow: 1px 1px 2px #000;
}

.p--right .p--btn:active:after {
    background: transparent;
}

.p--right .p--btn:hover:before {
    opacity: 1;
}

.p--right .p--btn:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 15px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

/* Social Media */
.Social-Media .social--holder {
    margin-top: 100px;
    position: relative;
    height: 250px;
}

.Social-Media .social--box {
    padding: 10px 20px;
    width: 100%;
    height: 60%;
    justify-content: space-around;
    background: linear-gradient(to right, rgba(223, 223, 223, 0.4) 0%, rgba(223, 223, 223, 0.2) 100%);
    box-shadow: 5px 5px 15px 0.2px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(9px);
    border-radius: 25px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.Social-Media .social--box  p {
    font-weight: 600;
}

/* Icon setup */
.Social-Media .icons--holder {
    position: absolute;
}

.Social-Media .Skill-image-holder {
    animation: none;
}

/* Twitch icon */
.Social-Media .s--twitch {
    position: absolute;
    transform: rotate(12deg);
    bottom: -90px;
    left: 90px;
}

.Social-Media .s--twitch.rotate {
    animation: throtate 1s infinite alternate ease-in-out;
}

@keyframes throtate {
    0%{
        transform: rotate(12deg);
    }

    30% {
        transform: rotate(-50deg);
    }

    100% {
        transform: rotate(365deg);
    }
}

.Social-Media .s--twitch .Skill-image-holder {
    width: 130px;
}

/* Discord icon */
.Social-Media .s--discord {
    position: absolute;
    transform: rotate(-30deg);
    top: 60px;
    left: 190px;
}

.Social-Media .s--discord.rotate {
    animation: drotate 1.2s infinite alternate ease-in-out;
}

@keyframes drotate {
    0%, 100% {
        top: 60px;
        left: 190px;
    }
    5%, 25%, 45%, 65%, 85% {
        left: 180px;
    }
    15%, 35%, 55%, 75%, 95% {
        left: 200px;
    }
    100% {
        top: 140px;
        left: 190px;
    }
}

.Social-Media .s--discord .Skill-image-holder {
    width: 130px;
}

/* Instagram icon */
.Social-Media .s--twitter {
    position: absolute;
    transform: rotate(-15deg);
    bottom: -110px;
    left: 290px;
    transition: all 0.2s ease-in-out;
}

.Social-Media .s--twitter.rotate {
    animation: trotate 4s infinite alternate ease-in-out;
}

@keyframes trotate {
    0% {
        bottom: -110px;
        left: 290px;
    }
    5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
        bottom: -120px;
    }
    10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
        bottom: -100px;
    }
    70% {
        left: 900px;
    }
    100% {
        left: 290px;
    }
}

/* Twitter icon */
.Social-Media .s--twitter .Skill-image-holder {
    width: 160px;
}

.Social-Media .social--box {
    display: flex;
    font-size: 23px;
    color: #fff;
    align-items: center;
}

/* Instagram icon */
.Social-Media .s--instagram {
    position: absolute;
    transform: rotate(20deg);
    top: 80px;
    left: 410px;
}

.Social-Media .s--instagram.rotate {
    animation: irotate 2s infinite alternate ease-in-out;
}

@keyframes irotate {
    0%, 100% {
        top: 80px;
        left: 410px;
        transform: rotate(20deg);
    }
    5%, 25%, 45%, 65%, 85% {
        transform: rotate(20deg);
    }
    15%, 35%, 55%, 75%, 95% {
        transform: rotate(-20deg);
    }
    70% {
        top: 130px;
    }
}

.Social-Media .s--instagram .Skill-image-holder {
    width: 130px;
}

/* Github icon */
.Social-Media .s--github .Skill-image-holder {
    width: 100px;
}

.Social-Media .social--box p {
    font-size: 25px;
    letter-spacing: 2px;
}

.Social-Media .social--box button {
    text-decoration: none;
    width: 145px;
    height: 50px;
    border-radius: 10px;
    font-size: 25px;
    font-weight: 650;
    line-height: 48px;
    color: #fff;
    border: none;
    background: linear-gradient(to right, #73DF7E 0%, #A373DF 100%);
    box-shadow: 3px 3px 5px 1px rgba(0,0,0, 0.3), inset 11px 10px 25px -8px rgb(255, 255, 255);
    transition: all 0.3s ease-in-out;

}

.Social-Media .social--box button:hover {
    cursor: pointer;
    width: 146px;
    transform: translateX(-3px) translateY(-3px);
    box-shadow: 4px 4px 5px 1px rgba(0,0,0, 0.5), inset 14px 14px 15px -8px rgb(255, 255, 255);
}

@media only screen and (max-width: 1200px) {
    .title-holder h2 {
        font-size: 28px !important;
    }

    .title-holder h2 > span {
        letter-spacing: 9vh;
    }

    .skills--box {
        width: 85%;
    }

    .skill--title {
        font-size: 18px !important;
    }

    .skill--short {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .spline-cube {
        display: none;
        visibility: hidden;
    }
    
    .title-holder h2 > span {
        letter-spacing: 8vh;
    }

    .text-holder > p {
        width: 100%;
        font-size: 14px;
    }

    .static--holder .info--holder h3 {
        font-size: 28px;
    }

    .static--holder .info--holder p {
        font-size: 16px;
    }

    .skills--box {
        width: 100%;
    }

    .skill--title {
        font-size: 21px;
    }

    .skill-right {
        width: 45%;
    }

    .skill-left {
        width: 55%;
    }

    .skill-left .skill--short {
        width: 80%;
        font-size: 16px;
        font-weight: 100;
    }

    .skill-left .skill--short p {
        font-size: 16px !important;
        font-weight: 300;
    }

    .skill--position {
        font-size: 18px;
    }

    .skills--box .Skill-image-holder:nth-child(1), .Skill-image-holder:nth-child(7), .Skill-image-holder:nth-child(8),.Skill-image-holder:nth-child(3), .Skill-image-holder:nth-child(5)   {
        display: none;
    }


    .skill-left ul li p {
        font-size: 14px;
    }

    .Piece--word h2 {
        font-size: 30px;
    }

    .p--right .p--btn {
        width: 200px;
        height: 45px;
        line-height: 45px;
    }

    .Social-Media .s--twitch {
        left: 20px;
    }

    .Social-Media .s--discord {
        left: 100px;
    }

    .Social-Media .s--twitter {
        left: 210px;
    }

    .Social-Media .s--instagram {
        left: 310px;
    }

    .Social-Media .social--box p {
        font-size: 21px;
        letter-spacing: 2px;

    }
}

@media only screen and (max-width: 830px) {
    .spline-cube {
        display: none;
        visibility: hidden;
    }

    .title-holder h2 {
        font-size: 30px !important;
    }

    .title-holder h2 > span {
        opacity: 0.2;
        letter-spacing: 45px;
        margin: 0 0 0 3%;
    }

    .skill-left .skill--title p {
        font-size: 16px;
    } 
}

@media only screen and (max-width: 500px) {
    .spline-cube {
        display: none;
        visibility: hidden;
    }

    .mf {
        display: block;
        visibility: visible;
    }
    
    section h2 {
        font-size: 28px;
        margin-bottom: 50px;
    }
    .ball1 {
        width: 400px;
        height: 400px;
    }
    
    .ball2 {
        width: 400px;
        height: 400px;
    }
    
    .ball3 {
        width: 400px;
        height: 400px;
    }

    .ball4 {
        width: 450px;
        height: 450px;
    }

    .ball5 {
        width: 450px;
        height: 450px;
        right: -150px;
        top: 3150px;
    }
    
    .title-holder h2 {
        margin-left: 19px;
        font-size: 23px !important;
    }

    .title-holder h2 > span {
        opacity: 0.2;
        letter-spacing: 15px;
        margin: 0 0 0 3%;
    }

    .images-holder {
        display: none;
    }

    .text-holder {
        width: 90%;
    }
    
    .text-holder > p {
        width: 100%;
        font-size: 12px;
        line-height: 24px;
    }

    .about-me {
        margin: 0;
    }

    .static--holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }

    .static--holder > div {
        width: 43%;  
        margin-bottom: 20px;  
    }

    .skill--title {
        font-size: 18px;
    }

    .skill-left {
        width: 99%;
    }

    .skill-right {
        display: none;
        width: 1%;
    }

    .skill--title div {
        width: 45px;
        padding: 5px;
    }

    .skill-left .skill--short {
        width: 90%;
        font-size: 14px;
        line-height: 24px;
    }

    .skill--position {
        font-size: 16px;
        margin-bottom: -20px;
    }

    .skill-left ul li p {
        font-size: 14px;
    }

    .Piece--word h2 {
        font-size: 20px;
    }

    .projects-holder {
        border-radius: 30px;
        height: 250px;
    }

    .projects-holder:hover::before, .projects-holder:hover::after {
        height: 250px;
    }
    
    .projects-holder .p--left .p--title {
        font-size: 18px;
    }

    .projects-holder .p--left p {
        width: 300px;
        font-size: 12px;
        font-weight: 600;
    }

    .p--right .p--btn {
        width: 200px;
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        border-radius: 12px;
    }

    .Social-Media .social--box {
        width: 90%;
        height: 50%;
    }

    .Social-Media  .Skill-image-holder {
        width: 100px !important;
    }

    .Social-Media .s--github .Skill-image-holder {
        display: none;
    }

    .Social-Media .social--box p {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .Social-Media .social--box button {
        width: 100px;
        height: 40px;
        font-size: 17px;
        line-height: 42px;
    }

    .Social-Media .social--box button:hover {
        width: 103px;
        height: 42px;
    }
} 

@media only screen and (max-width: 440px) {
    section h2 {
        font-size: 26px;
    }

    .Social-Media .social--box p {
        font-size: 11px;
    }
}

@media only screen and (max-width: 410px) {
    .spline-cube {
        display: none;
        visibility: hidden;
    }

    section {
        margin-top: 120px;
    }
    .title-holder h2 {
        font-size: 21px !important;
        margin-bottom: 20px !important;
    }

    section h2 {
        font-size: 22px !important;
    }

    .text-holder > p {
        font-size: 11px;
        line-height: 24px;
        margin-top: -5px;
    }

    .Statics {
        margin-top: 250px !important;
    }

    .skill--title h5{
        font-size: 15px !important;
    }

    .skill--short p {
        font-size: 13px;
    }

    .skill--position p {
        font-size: 12px;
    }

    .skill--position--subset ul p {
        font-size: 11px !important;
    }

    .Piece--word h2 {
        font-size: 19px !important;
    }

    .Projects .projects-holder .p--title {
        font-size: 16px;
    }

    .Projects .projects-holder .p--second-title {
        font-size: 12px;
        width: 32vh;
    }

    .Social-Media .s--twitch {
        bottom: -60px;
        left: 20px;
    }

    .Social-Media .s--discord {
        top: 80px;
        left: 80px;
    }

    .Social-Media .s--twitter {
        bottom: -70px;
        left: 190px;
    }

    .Social-Media .s--instagram {
        top: 70px;
        left: 240px;
    }
}