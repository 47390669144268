.c--product-info {
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin-top: 100px;
    height: 400px;
}

.c--product-info .product-info {
    width: 35%;
    text-align: left;
    font-size: 15px;
    font-weight: 300;
    line-height: 27px;
}

.order-select-one {
    width: 25%;
    text-align: left;
}

.c--product-info input {
    margin-top: 20px;
    width: 98%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #fff;
    color: #ffffff7a;
    font-size: 12px;
    font-weight: 200;
    font-style: italic;
    padding: 0px 0px 5px 10px;
}

.select-color {
    margin-top: 80px;
}

.select-color > p {
    font-size: 21px;
    font-weight: 400;
}

.colors {
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.color-holder {
    width: 34px;
    height: 34px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.color-holder.selected {
    border: 1px solid #00FF57; 
    pointer-events: none; 
}

.order-select-two {
    position: relative;
    width: 25%;
    text-align: left;
}

.select-size {
    margin-top: 80px;
}

.select-size > p {
    font-size: 21px;
    font-weight: 400;
}

.sizes {
    text-align: center;
    line-height: 34px;
    display: flex;
    justify-content: space-between;
}

.sizes div {
    width: 48px;
    height: 34px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 19px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.size.selected {
    border: 1px solid #00FF57; 
    pointer-events: none; 
}

.submit-order {
    width: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
}

.submit-order .order-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 140px;
    height: 45px;
    background-color: #00FF57;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0, 0.6);
    transition: all 0.3s ease-in-out;
}

.submit-order .order-btn:hover {
    transform: translate(-2px, -2px);
    box-shadow: 6px 6px 10px 2px rgba(0,0,0,1);
}

.submit-order .order-btn p {
    margin: 0;
    line-height: 45px;
    color: #fff;
    font-size: 19px;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.8);
}

.p-price {
    position: absolute;
    bottom: -30px;
    font-size: 25px;
    font-weight: 600;
}

.to-store {
    position: fixed;
    cursor: pointer;
    width: 80px;
    height: 55px;
    top: 40%;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: #00FF57;
    border-radius: 0 8px 8px 0;
    transition: all 0.3s ease-in-out;
    z-index: 999999;
}

.store-img-holder {
    width: 35px;
    height: 35px;
    margin: 8px auto;
    transition: all 0.3s ease-in-out;
}

.to-store img {
    object-fit: cover;
    width: 100%;
    filter: drop-shadow(1px 1px 5px rgba(0,0,0, 0.7));
}

.to-store:hover {
    width: 110px;
}

.to-store:hover .store-img-holder {
    transform: translateX(-170%);
    opacity: 0;
}

.to-store p {
    position: absolute;
    bottom: -19px;
    left: 20px;
    color: #fff;
    font-size: 27px;
    font-weight: 600;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.to-store:hover p {
    transform: translateX(0);
    opacity: 1;
}

.product-images {
    margin-top: 12%;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    display: flex;
    gap: 20px;
}

.product-images .p-img-holder {
    position: relative;
    width: 300px;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
}

.product-images .p-img-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.good-to-know {
    margin-top: 10%;
    text-align: left;
    color: #fff;
}

.gtk-title {
    font-size: 38px;
    font-weight: 700;
}

.gtk-title span {
    color: #D04D4D;
}

.c--product-details {
    margin-top: 5%;
}

.c--product-title {
    font-size: 25px;
    font-weight: 600;
}

.c--product-details p {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
}

.c--product-title span {
    color: #00FF57;
}

.c--error-messages {
    font-size: 15px;
    margin-top: 20%;
}

@media only screen and (max-width: 500px) {
    .to-store {
        display: none;
        visibility: hidden;
    }

    .c--product-info {
        margin-top: 5%;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 700px;
    }

    .order-select-one {
        width: 47%;
    }

    .order-select-two {
        width: 47%;
    }

    .c--product-info .product-info {
        width: 100%;
        font-size: 14px;
    }

    .product-images {
        justify-content: center;
        height: 100%;
        margin-top: 35%;
        display: inline-flex;
        flex-wrap: wrap;
    }

    .sizes {
        height: 100px;
        display: inline-flex;
        flex-wrap: wrap;
    }

    .colors {
        width: 50%;
    }

    .good-to-know {
        margin-top: 30%;
    }

    .c--product-details {
        margin-top: 20%;
    }

    .c--space {
        margin-bottom: 150px;
    }
}