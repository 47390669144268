.m-content--holder {
    cursor: pointer;
    position: relative;
    margin-top: 20px;
    background-color: #090A0E;
    width: 100%;
    height: 70px;
    display: flex;
    /* padding-left: 25px; */
    align-items: center;
    text-align: center;
    border-radius: 9px;
    color: #fff;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.4), inset 5px 5px 15px -15px rgb(255, 255, 255);
    transition: all 0.3s ease-in-out;
}

.m-content--holder:hover {
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.6), inset 6px 6px 15px -13px rgb(255, 255, 255) ;
}

.m-icon--holder {
    position: relative;
    width: 20%;
    margin-left: 25px;
}

.m-content--holder .m-icon {
    position: relative;
    width: 55px;
}

.m-content--holder .m-icon img {
    width: 100%;
}

.m-content--holder .m-title {
    width: 47%;
    letter-spacing: 2px;
    font-size: 17px;
}