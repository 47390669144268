.gg {
    width: 100%;
    margin: 25% auto;
}
.gg p {
    color: #fff;
    font-size: 150px;
}

/* .hide-this {
    display: none;
    visibility: hidden;
} */