.pc {
    width: 320px;
    height: 370px;
    background: rgba( 255, 255, 255, 0.13);
    box-shadow: 5px 5px 25px 1px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 25px;
    border: 1px solid rgba( 255, 255, 255, 0.5);
    margin-top: 91px;
    color: #fff;
    transition: all 0.1s ease-out;
}

.pc-img-holder {
    width: 87%;
    height: 190px;
    display: flex;
    margin-top: 20px !important;
    margin: 0 auto;
    border-radius: 15px;
}

.pc-img-holder img {
    object-fit: cover;
    border-radius: 15px;
    width: 100%;
}

.pc-info {
    text-align: left;
    padding: 0 20px;
}

.pc-info p:nth-child(1) {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 500;
}

.pc-info p:nth-child(2) {
    margin-top: 0;
    font-size: 13px;
    font-weight: 200;
}

.price-btn {
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pc-price {
    display: flex;
    align-items: flex-end;
}

.pc-price p:nth-child(1) {
    font-size: 12px;
    font-weight: 100;
}

.pc-price p:nth-child(2) {
    margin-left: 3px;
    line-height: 9px;
    color: #00FF57;
    font-size: 19px;
    font-weight: 600;
}

.pc-btn {
    cursor: pointer;
    width: 80px;
    height: 30px;
    background-color: rgba(0, 255, 87, 0.6);
    border-radius: 6px;
    border: 1px solid rgba(0, 255, 87, 0.0);
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.pc-btn:hover {
    background: transparent;
    border: 1px solid rgba(0, 255, 87, 0.6);
}

@media only screen and (max-width: 500px) {
    .pc {
        margin-top: 45px;
    }
}