.ms {
    margin-top: 50px;
}

.ms p {
    color: #fff;
}

.m-socialmedia {
    margin-top: 100px;
}

.m--title p {
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
}

.ms--title {
    text-align: left;
    font-size: 18px;
}

.ms--title span {
    color: #D04D4D;
}

.m-position p:nth-child(2) {
    text-align: left;
}

.m-position .m-desc span:nth-child(1) {
    color: #00FF57;
}

.m-position .m-desc span:nth-child(2) {
    color: #00FFC1;
}

.m-position .m-desc span:nth-child(3) {
    color: #CE5353;
}

.m-logo {
    width: 100px;
    margin: 150px auto;
}

.m-logo img {
    width: 100%;
}